import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import monkey from '../images/monkey-with-knives.png'

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <SEO title="404: Not Found" />
        <h1>Page Not Found</h1>
        <img src={monkey} alt="Disgruntled monkey" />
      </Layout>
    )
  }
}

export default NotFoundPage
